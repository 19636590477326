import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  DefaultOptions,
  DocumentNode,
} from '@apollo/client';
import { GraphQLError } from 'graphql';

import { sha256 } from '../sha256';
import type { Sha256 } from '@/types/sha256';

import Logger from '@/utils/logger';
const logger = new Logger({ caller: 'utils.graphql' });

const backendCacheTimeInMinutes = 20;

import { getEnvData, getSimplifiedDeployedEnvName } from '@/utils/env';

const envData = getEnvData();
const simpleDeployedEnvName = getSimplifiedDeployedEnvName();

const graphServerUrl = process.env.NEXT_PUBLIC_GRAPHQL_API_HOST;
const elementApiUrl = process.env.CMS_ELEMENT_API_URL;
const diskCacheEnabled = envData.deployedEnvName.indexOf('local--') === 0 && envData.GRAPHQL_LOCAL_DISKCACHE === 'true';
const isCalledFromBrowser = typeof window !== 'undefined';
const isProd = () => envData.deployedEnvName === 'prod';
const isRunTimeOnServer = () => typeof process !== 'undefined' && process.env.npm_lifecycle_script?.indexOf('next build') === -1;

// Using GET requests means not using POST, which means there will not be caching at the CDN level.
// For now, we only want to use CDN cache (GET requests) on the client side or if: in production + on the server side + at run time (not build time).
// This reduces the amount of stale data that gets returned from GraphQL, limiting it to only when we could potentially bring Graph down with too much traffic.
const useGetRequests = isCalledFromBrowser || (isProd() && isRunTimeOnServer());

if (!graphServerUrl) {
  throw new Error('NEXT_PUBLIC_GRAPHQL_API_HOST environment variable is required.');
}

const augmentHeaders = (oldHeaders: object, additionalHeaders: object = {}) => {
  const newHeaders: {
    'x-pbs-kids-cms-element-api-url'?: string;
  } = Object.assign(
    {},
    oldHeaders,
    additionalHeaders,
  );

  if (elementApiUrl) {
    newHeaders['x-pbs-kids-cms-element-api-url'] = elementApiUrl;
  }

  return {
    headers: newHeaders,
  };
};

const clients: Array<{ key: string, client: ApolloClient<object> }> = [];

const getClientIdentity = () => {
  const clientSource = isCalledFromBrowser ? 'browser' : 'server';
  const clientName = `pbs.kids.website.${simpleDeployedEnvName}.${clientSource}`;

  const clientVersion = JSON.stringify({
    semver: envData.packageVersion,
    commit: envData.buildId || 'n/a',
    built: envData.buildTime || 'n/a',
  });

  return {
    clientName,
    clientVersion,
  };
};

const { clientName, clientVersion } = getClientIdentity();

const getClient = async (additionalHeaders: object = {}) => {
  const clientKey = JSON.stringify(additionalHeaders);
  const existingClient = clients.find((client) => client.key === clientKey);
  if (existingClient) {
    return existingClient.client;
  }

  const { createPersistedQueryLink } = await import('@apollo/client/link/persisted-queries');
  const { HttpLink, InMemoryCache } = await import('@apollo/client');
  const { setContext } = await import('@apollo/client/link/context');

  const httpLink = new HttpLink({ uri: graphServerUrl });

  const authLink = setContext((_, { headers }) => {
    return augmentHeaders(headers, additionalHeaders);
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  };

  const persistedQueriesLink = createPersistedQueryLink({
    useGETForHashedQueries: useGetRequests,
    sha256: (sha256 as Sha256),
  });

  const clientOptions: ApolloClientOptions<object> = {
    link: persistedQueriesLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
    name: clientName,
    version: clientVersion,
  };

  if (!isCalledFromBrowser) {
    // Disable Apollo Client caching when called on the server side.
    const { VoidCache } = await import('./client-caching');

    clientOptions.cache = new VoidCache();

    clientOptions.defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  }

  const client = new ApolloClient(clientOptions);

  clients.push({ key: clientKey, client });

  return client;
};

const truncate = (str: string, length: number) => {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};

const convertStringToDocNode = async (query: string) => {
  const { gql } = await import('@apollo/client');
  return gql(query);
};

const getQuerySummary = async (query: DocumentNode | string, variables?: object) => {
  query = typeof query === 'string' ? await convertStringToDocNode(query) : query;

  // If we don't ignore TypeScript here, an error is thrown: `Property 'name' does not exist on type 'DefinitionNode'. Property 'name' does not exist on type 'SchemaDefinitionNode'.`
  // This is apparently because the type definitions for DocumentNode are not correct.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  const queryName = typeof query === 'string' ? '' : query?.definitions?.[0]?.name?.value ?? '';
  const variablesAsString = variables ? truncate(JSON.stringify(variables), 120) : '';

  const msg = [];
  if (queryName) {
    msg.push(`\`${queryName}\``);
  } else if (typeof query === 'string') {
    msg.push(`\`${truncate(query, 50)}\``);
  }
  if (variablesAsString) {
    msg.push(`with variables ${variablesAsString}`);
  }
  return msg.join(' ');
};

const getHumanReadableErrorMessage = async (
  response: ApolloQueryResult<object|undefined>,
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  // Strip out any secrets from the headers output.
  const headers = Object.keys(additionalHeaders || {}).map((key) => `${key}: removed`);
  const contextDetails = {
    graphServerUrl,
    elementApiUrl,
    headers,
    response,
  };
  return `Error running query ${await getQuerySummary(query, variables)} : ${JSON.stringify(contextDetails, null, 1)}`;
};

function getGqlString(doc: DocumentNode) {
  return doc.loc && doc.loc.source.body;
}

const completionMessage = async (
  cached: boolean | null,
  startTime: number,
  query: DocumentNode | string,
  variables?: object,
) => {
  const duration = Date.now() - startTime;
  const summary = await getQuerySummary(query, variables);

  const getMessage = (speed = '') => cached === null ?
    `Returning ${speed ? speed + ' ' : ''}response after ${duration.toLocaleString()}ms for query ${summary}` :
    `Returning ${speed ? speed + ' ' : ''}${cached ? 'cached' : 'uncached'} response after ${duration.toLocaleString()}ms for query ${summary}`;

  if (duration > 1500) {
    logger.info(getMessage('🦥🦥🦥 VERY SLOW 🦥🦥🦥'));
  } else if (duration > 500) {
    logger.info(getMessage('🦥 SLOW 🦥'));
  }
};

const queryGraph = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
) => {
  const operation: {
    query: DocumentNode,
    variables?: object,
  } = {
    query: typeof query === 'string' ? await convertStringToDocNode(query) : query,
    variables,
  };
  const now = Date.now();
  const client = await getClient(additionalHeaders);

  return client
    .query(operation)
    .catch(async (response) => {
      const fatalError = new Error(
        await getHumanReadableErrorMessage(response, query, variables, additionalHeaders),
      );
      if (typeof onError === 'function') {
        onError([ fatalError ]);
      }
      throw fatalError;
    }).then(async (response) => {
      if (response?.errors?.length) {
        if (typeof onError === 'function') {
          onError(response.errors as Array<GraphQLError>);
        } else {
          logger.debug(
            await getHumanReadableErrorMessage(response, query, variables, additionalHeaders),
          );
        }
      }
      completionMessage(null, now, query, variables);
      return response.data;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let diskCacheInstance: any;

const getDiskCacheInstance = async () => {
  if (diskCacheInstance) {
    return diskCacheInstance;
  }
  // Type definitions are not available for ttl-file-cache and
  // I'm not sure a better way than this to suppress the TS errors.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  const DiskCache = await import('ttl-file-cache');

  // This dynamic import is done because a normal import causes issues in the browser.
  // eslint-disable-next-line new-cap
  return diskCacheInstance = new DiskCache.default({
    // default dir is '/tmp/ttl-file-cache' or whatever os.tempdir() resolves to on your operating system
    dir: `/tmp/${envData.packageName}/utils.graphql/ttl-file-cache`,
  });
};

const getQueryCacheKey = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const md5 = (await import('md5')).default;
  const queryAsString = typeof query === 'string' ? query : getGqlString(query)?.replace(/\s+/g, ' ');
  return md5(JSON.stringify({
    queryAsString,
    variables,
    additionalHeaders,
    graphServerUrl,
    elementApiUrl,
  }));
};

const queryGraphWithDiskCache = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const cacheKey = await getQueryCacheKey(query, variables, additionalHeaders);
  const startTime = Date.now();
  const cache = await getDiskCacheInstance();
  const cachedResponse = cache.get(cacheKey);

  if (cachedResponse) {
    completionMessage(true, startTime, query, variables);
    return JSON.parse(cachedResponse.toString());
  }

  const response = await queryGraph(query, variables, additionalHeaders);
  completionMessage(false, startTime, query, variables);
  await cache.set(cacheKey, JSON.stringify(response), (backendCacheTimeInMinutes * 60));
  return response;
};

const initProgressBar = async () => {
  if (typeof window !== 'undefined') {
    return await import('@/utils/progress-bar').then((module) => module.default);
  } else {
    return {
      start: () => {},
      complete: () => {},
    };
  }
};

const queryGraphClient = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
) => {
  if (!isCalledFromBrowser) {
    throw new Error('queryGraphClient should only be called from a client. Use queryGraphServer instead.');
  }

  const progressBar = await initProgressBar();

  progressBar.start();

  const data = await queryGraph(query, variables, additionalHeaders, onError)
    .catch((error) => {
      progressBar.complete();
      logger.error(error);
      throw new Error(error);
    });
  progressBar.complete();
  return data;
};

const queryGraphServer = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
  allowDiskCache = true,
) => {
  if (isCalledFromBrowser) {
    throw new Error('queryGraphServer should only be called from the server. Use queryGraphClient instead.');
  }
  return diskCacheEnabled && allowDiskCache ?
    queryGraphWithDiskCache(query, variables, additionalHeaders) :
    queryGraph(query, variables, additionalHeaders);
};

export {
  queryGraphClient,
  queryGraphServer,
};
