import Logger from '@/utils/logger';

// UA = Universal Analytics; GA = Google Analytics (4)

const logger = new Logger({ caller: 'managers.Analytics' });

const globalUACode = 'UA-4005001-1';
const globalTrackerName = 'pbs';

const sendEventToGA = (eventData: Record<string, unknown>) => {
  logger.debug('sendEventToGA', eventData);
  const dataLayer = window.dataLayer = window.dataLayer || [];
  dataLayer.push(eventData);
};

const sendEventToUA = (eventData: Record<string, string|number>, trackerName?: string) => {
  if (window.ga && typeof window.ga === 'function' && eventData?.hitType === 'event') {
    const tracker = trackerName || globalTrackerName;
    logger.debug('sendEventToUA', tracker, eventData);
    window.ga(tracker + '.send', eventData);
  }
};

const clearGAEcommerceObj = () => {
  const dataLayer = window.dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
};

const checkIfAnalyticsLoaded = () => {
  return new Promise((resolve, reject) => {
    const timeStart = Date.now();
    const timeout = 15000;

    const isAnalyticsLoaded = () => {
      if (window.ga && typeof window.ga === 'function') {
        resolve(window.ga);
        return;
      } else if (Date.now() - timeStart > timeout) {
        reject(new Error('Google analytics not detected before timeout.'));
        return;
      } else {
        setTimeout(isAnalyticsLoaded, 500);
      }
    };

    isAnalyticsLoaded();
  });
};

const initUATrackingbyCode = (uaCode: string, trackerName: string) => {
  if (!uaCode) return;

  checkIfAnalyticsLoaded()
    .then(() => {
      logger.debug('initUATrackingbyCode(' + trackerName + ' ' + uaCode + ')');
      window.ga('create', uaCode, 'auto', { name: trackerName });
    })
    .catch((err) => logger.warn(err + ' Universal Analytics may be affected.'));
};

const initGlobalAnalytics = () => initUATrackingbyCode(globalUACode, globalTrackerName);

const initProducerAnalytics = (showAnalyticsId: string, nola: string) => initUATrackingbyCode(showAnalyticsId, nola);

export {
  clearGAEcommerceObj,
  initGlobalAnalytics,
  initProducerAnalytics,
  sendEventToGA,
  sendEventToUA,
};
