import { getOptimizedImageUrl } from '@/managers/Images';
import { ReactNode, useContext } from 'react';
import { Translator } from '@/utils/language-localization/system';
import { useRouter } from 'next/router';
import Head from 'next/head';
import LanguageLocalizer from '@/utils/language-localization';

export enum PageMetaDefaults {
  DEFAULT_PAGE_DESCRIPTION = 'defaultPageDescription',
  DEFAULT_PAGE_IMAGE = 'defaultPageImage',
  DEFAULT_VIDEO_PAGE_DESCRIPTION = 'defaultVideoPageDescription',
}

const getPageDescription = (__t: Translator, description?: string) => {
  if (!description || description === PageMetaDefaults.DEFAULT_PAGE_DESCRIPTION) {
    return __t(PageMetaDefaults.DEFAULT_PAGE_DESCRIPTION);
  }

  if (description === PageMetaDefaults.DEFAULT_VIDEO_PAGE_DESCRIPTION) {
    return __t(PageMetaDefaults.DEFAULT_VIDEO_PAGE_DESCRIPTION);
  }

  return description;
};

const getPageImageUrl = (imageUrl?: string) => {
  if (!imageUrl || imageUrl === PageMetaDefaults.DEFAULT_PAGE_IMAGE) {
    imageUrl = '/puma/images/pwa/PBSKidsLogo.png';
  }

  return process.env.NEXT_PUBLIC_SITE_CDN + getOptimizedImageUrl(imageUrl, 600);
};

const getPageTitle = (title?: string, omitBrandSuffix = false) => {
  if (!title) {
    return 'PBS KIDS';
  }

  if (omitBrandSuffix) {
    return title;
  }

  return title + ' | PBS KIDS';
};

type Props = {
  pageTitle?: string,
  pageDescription?: string,
  pageImageUrl?: string,
  omitBrandSuffix?: boolean,
  children?: ReactNode,
}

export default function PbsKidsHead({
  pageTitle,
  pageDescription,
  pageImageUrl,
  children,
  omitBrandSuffix = false,
}: Props) {
  const router = useRouter();
  const { __t, locale } = new LanguageLocalizer( useContext, 'components/global/Head' );

  // Clean/validate/update supplied metadata
  const pageUrl = process.env.NEXT_PUBLIC_SITE_CDN + router.asPath;
  pageTitle = getPageTitle(pageTitle, omitBrandSuffix);
  pageDescription = getPageDescription(__t, pageDescription);
  pageImageUrl = getPageImageUrl(pageImageUrl);

  return <>
    <Head>
      {/* Standard Tags */}
      <meta key='charset' charSet='utf-8' />
      <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1' />

      <title>{ pageTitle }</title>
      <meta key='description' name='description' content={ pageDescription } />;

      {/* OpenGragh Tags */}
      <meta key='og:type' property='og:type' content='website' />
      <meta key='og:locale' property='og:locale' content={ locale } />
      <meta key='og:site_name' property='og:site_name' content='PBS KIDS' />
      <meta key='og:url' property='og:url' content={ pageUrl } />
      <meta key='og:title' property='og:title' content={ pageTitle } />
      <meta key='og:description' property='og:description' content={ pageDescription } />
      <meta key='og:image' property='og:image' content={ pageImageUrl } />

      {/* Twitter Tags */}
      <meta key='twitter:card' name='twitter:card' content='summary_large_image' />
      <meta key='twitter:site' name='twitter:site' content='@pbskids' />
      <meta key='twitter:creator' name='twitter:creator' content='@pbskids' />
      <meta key='twitter:title' name='twitter:title' content={ pageTitle } />
      <meta key='twitter:description' name='twitter:description' content={ pageDescription } />
      <meta key='twitter:image' name='twitter:image' content={ pageImageUrl } />

      {/* Page-defined meta tags, links, and resources. */}
      { children ? children : '' }
    </Head>
  </>;
}
