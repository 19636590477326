// eslint-disable-next-line @typescript-eslint/no-var-requires
const { hexToRgbAsString } = require('../colors');

import {
  Asset,
  CmsSite,
  Maybe,
  PbsKidsPage,
  PbsKidsTheme,
} from '@/types/pbskids-graph';

import type {
  ThemeCandidate,
  ContextualizedTheme,
  ResolvedPageThemes,
  ThemeContextName,
  ThemeStyleType,
  ThemeVariableGroup,
} from './types';

import { defaultTheme } from './defaultTheme';
import { getOptimizedImageSetCss } from '@/managers/Images';

import Logger from '@/utils/logger';
const logger = new Logger({ caller: 'utils.theming-system.index' });

const themeVariablePrefix = '--pbsk-theme-';
const isProduction = process.env.NODE_ENV === 'production';
const isUnitTestSuite = process.env.NODE_ENV === 'test';
const noThemeSlug = '--no-theme-selected-in-theme-debugger--';

const transparentColor = 'rgb(0 0 0 / 0)';
const singleTransparentPixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const defaultThemeWithContext: ContextualizedTheme = {
  themeContextName: 'page',
  theme: defaultTheme,
  sourceDescription: 'Hardcoded default theme',
  canStationOverride: true,
};

const deepClone = (obj: object) => JSON.parse(JSON.stringify(obj));

const getThemeFromAncestors = (ancestors: Maybe<Array<PbsKidsPage>>): Array<PbsKidsTheme> => {
  // Iterate over ancestors until we find a theme.
  if (Array.isArray(ancestors) && ancestors.length) {
    for (let generation = ancestors.length; generation > 0; generation--) {
      const ancestor = ancestors.find((ancestor) => ancestor?.level === generation);
      if (Array.isArray(ancestor?.theme) && ancestor.theme?.length) {
        return ancestor.theme as Array<PbsKidsTheme>;
      }
    }
  }
  return [];
};

const findThemeByContextName = (themes: Array<ThemeCandidate>, contextName: ThemeContextName) => {
  return themes.find((theme) => theme.themeContextName === contextName);
};

const metadataFields = [
  '__typename',
  'id',
  'slug',
  'title',
];

const isThemeMetaDataField = (fieldName: string) => {
  return metadataFields.indexOf(fieldName) > -1;
};

const isCssFieldName = (key: string) => {
  const notNeededInCssOutput = [
    ...metadataFields,
    'sponsorLogoStyle',
    'stationLogoStyle',
  ];
  return !notNeededInCssOutput.includes(key) && Object.keys(defaultTheme).includes(key);
};

const isValidTheme = (theme: PbsKidsTheme) => {
  if (typeof theme === 'object' && theme !== null) {
    const dataFields = Object.keys(theme).filter((key) => !isThemeMetaDataField(key));
    const expectedDataFields = Object.keys(defaultTheme).filter((key) => !isThemeMetaDataField(key));

    if (dataFields.length === expectedDataFields.length) {
      const metadataFields = Object.keys(theme).filter((key) => isThemeMetaDataField(key));

      if (metadataFields.length !== metadataFields.length) {
        return false;
      }

      return true;
    }
  }
  return false;
};

const getSiteTheme = async (site: CmsSite|null): Promise<PbsKidsTheme|null> => {
  if (site && !isUnitTestSuite) {
    const { queryGraphServer } = await import('@/utils/graphql');
    const getSiteThemeQuery = await import('@/queries/themes/getSiteTheme.graphql');

    try {
      const response = await queryGraphServer(getSiteThemeQuery.default, { site });
      return response?.siteSettings?.theme?.[0] || null;
    } catch (e) {
      logger.error(`getSiteTheme("${site.toString()}")`, e);
    }
  }

  return null;
};

const isColorField = (fieldName: string) => !!fieldName.match(/Color/) && isCssFieldName(fieldName);
const isImageField = (fieldName: string) => !!fieldName.match(/Image/) && isCssFieldName(fieldName);

const transformThemeValues = (theme: PbsKidsTheme) => {
  const transformedTheme = {} as ThemeVariableGroup;

  for (const key of Object.keys(theme)) {
    if (isCssFieldName(key)) {
      if (isImageField(key)) {
        const cmsValue = theme?.[key as keyof PbsKidsTheme] as Array<ThemeVariableGroup>;
        const image = cmsValue?.[0];
        const imageWidth = parseInt(image?.width ? image.width.toString() : '-1');
        const imageHeight = parseInt(image?.height ? image.height.toString() : '-1');

        if (image?.url && imageHeight > 0 && imageWidth > 0) {
          transformedTheme[`${key}-aspectRatio`] = (imageWidth / imageHeight).toString();
        } else {
          transformedTheme[key] = `url('${singleTransparentPixel}')`;
        }
      } else if (isColorField(key)) {
        transformedTheme[key] = (theme as ThemeVariableGroup)[key] || transparentColor;
      } else {
        transformedTheme[key] = `${(theme as ThemeVariableGroup)[key]?.toString()}`;
      }
    }
  }

  return transformedTheme;
};

const sortThemeKeysToMatchDefault = (theme: PbsKidsTheme): PbsKidsTheme => {
  const defaultKeys = Object.keys(defaultTheme);
  const sortedKeys: Array<string> = [];

  for (const key of defaultKeys) {
    sortedKeys.push(key);
  }

  for (const key of Object.keys(theme)) {
    if (!sortedKeys.includes(key)) {
      sortedKeys.push(key);
    }
  }

  const sortedTheme: PbsKidsTheme = {} as PbsKidsTheme;
  for (const key of sortedKeys) {
    (sortedTheme as ThemeVariableGroup)[key] = (theme as ThemeVariableGroup)[key];
  }

  return sortedTheme;
};

const sortThemeKeysInContext = (theme: ContextualizedTheme) => {
  const sorted = deepClone(theme);
  sorted.theme = sortThemeKeysToMatchDefault(sorted.theme);
  return sorted;
};

const filterOutUselessThemeValues = (theme: PbsKidsTheme) => {
  for (const key of Object.keys(theme)) {
    if (!isCssFieldName(key) && key !== 'title') {
      delete (theme as ThemeVariableGroup)[key];
    }
  }
  return theme;
};

const isEmptyThemeValue = (value: string | undefined | null | Array<object>) => {
  return !value || (Array.isArray(value) && value?.length === 0);
};

/*
  The function `cleanupThemeValues` does the following to page themes:

  #1. If values do not exist in page theme, which do exist in the default app theme, then the page theme will need to `unset` them.
      This allows us to always have the default theme present without having to inject theme styles manually into every single page,
      while at the same time not accidentally overriding the intended theme on pages that do inject their own them styles.

  #2. Sorts the keys from passed `themes` to match the default theme, which matches the order in the CMS admin panel. Just makes
      it easier for us humans to reason through.
*/
const cleanupThemeValues = (
  themeStyleType: ThemeStyleType,
  themes: Array<ContextualizedTheme>,
): Array<ContextualizedTheme> => {
  // Avoid accidentally mutating the original array.
  themes = deepClone(themes);

  // The global default theme doesn't need to get cleaned up.
  if (themeStyleType === 'globalDefault') {
    return themes.map((theme: ContextualizedTheme) => {
      theme.theme = filterOutUselessThemeValues(theme.theme);
      return theme;
    });
  }

  const pageTheme = findThemeByContextName(themes, 'page') as ContextualizedTheme;
  const pageThemeSorted = sortThemeKeysInContext(pageTheme);

  const otherThemes = themes.filter((theme) => theme.themeContextName !== 'page');
  const otherThemesSorted = otherThemes.map(sortThemeKeysInContext);

  const cleanedUpPageTheme = deepClone(pageThemeSorted);
  const globalDefault = defaultTheme as ThemeVariableGroup;
  const thisPageTheme = pageThemeSorted.theme as ThemeVariableGroup;
  const cleanedUpThemeValues = filterOutUselessThemeValues(cleanedUpPageTheme.theme) as ThemeVariableGroup;

  // The most important work (#1) is done here.
  for (const key of Object.keys(defaultTheme)) {
    if ( isCssFieldName(key) && !isEmptyThemeValue(globalDefault[key]) && isEmptyThemeValue(thisPageTheme[key]) ) {
      cleanedUpThemeValues[key] = 'unset';
    }
  }

  return [
    cleanedUpPageTheme,
    ...otherThemesSorted,
  ];
};

const postProcessTheme = (theme: PbsKidsTheme) => {
  const processedTheme = deepClone(theme) as PbsKidsTheme;

  // Per Kem Alily 2/2/2024 <https://www.pivotaltracker.com/story/show/186938607/comments/240104374>
  // > If both a background image and an accent color are present, then the accent color should be
  //   ignored as accent colors are for pages that have no background image and mimic the behavior
  //   if a background image was present.

  if (
    processedTheme.backgroundImage?.[0]?.url &&
    processedTheme.accentBackgroundColor
  ) {
    processedTheme.accentBackgroundColor = null;
  }

  return processedTheme;
};

const getImageFieldsFromCmsTheme = (cmsTheme: PbsKidsTheme) => {
  const imageFields = Object.keys(cmsTheme).filter((fieldName: string) => isImageField(fieldName));
  return imageFields.map((fieldName: string) => {
    const url = (((cmsTheme[fieldName as keyof PbsKidsTheme] as Array<Asset>)?.[0]) as Asset)?.url;
    return {
      fieldName,
      url,
    };
  }).filter(({ url }) => !!url);
};

const getResponsiveImageSets = (cmsTheme: PbsKidsTheme, selector: string) => {
  const cssStrings: Array<string> = [];
  for (const image of getImageFieldsFromCmsTheme(cmsTheme)) {
    if (image.url) {
      cssStrings.push(
        getOptimizedImageSetCss(
          image.url,
          themeVariablePrefix + image.fieldName,
          selector,
          image.fieldName === 'backgroundImage' ? 3 : 1,
        ),
      );
    }
  }
  return cssStrings.join('\n');
};

const getCssVariableDeclarationsFromThemeContext = (cmsTheme: PbsKidsTheme, contextName: ThemeContextName) => {
  if (cmsTheme.slug === noThemeSlug) {
    return '';
  }

  const selector = contextName === 'page' ? ':root' : `[data-theme-context='${contextName}']`;

  const cssStrings = [
    `${selector} {`,
    `  /* ${cmsTheme.title} */`,
  ];

  const theme = transformThemeValues( postProcessTheme(cmsTheme) );

  if (Object.keys(theme).length === 0) {
    if (isProduction) {
      return '\n';
    } else {
      cssStrings.push(
        '  /*',
        `      It appears all the ${contextName} context values were already set identically in the body context, so no variables were output.`,
        '      We are just letting the cascade do its thing!',
        '  */',
      );
    }
  } else {
    for (const key of Object.keys(theme)) {
      cssStrings.push(`  ${themeVariablePrefix}${key}: ${theme[key]};`);
      if (theme[key]?.startsWith('#')) {
        cssStrings.push(`  ${themeVariablePrefix}${key}-rgb: ${hexToRgbAsString(theme[key])};`);
      }
    }
  }

  cssStrings.push('}', '');

  cssStrings.push(
    getResponsiveImageSets(cmsTheme, selector),
  );

  return cssStrings.join('\n');
};

const themesToCss = (themes: Array<ContextualizedTheme>) => {
  const cssStrings: Array<string> = [];

  for (const theme of themes) {
    cssStrings.push(
      getCssVariableDeclarationsFromThemeContext(
        theme.theme,
        theme.themeContextName,
      ),
    );
  }

  return cssStrings.join('\n');
};

const addPageThemeCss = (
  themeStyleType: ThemeStyleType,
  themeCandidates: Array<ThemeCandidate>,
  themesToApply: Array<ContextualizedTheme>,
): ResolvedPageThemes => {
  // Get the station logo style from the last theme in the array.
  // Typically this will be the page theme, but if a masthead theme is included, it will pull from there.
  const primaryTheme = deepClone(themesToApply).map((theme: ContextualizedTheme) => theme.theme).pop();

  return {
    themes: themesToApply,
    themeCandidates,
    primaryTheme,
    css: themesToCss( cleanupThemeValues(themeStyleType, themesToApply) ),
    hasMastheadTheme: !!findThemeByContextName(themesToApply, 'masthead'),
  };
};

const moveBodyToBeginning = (array: Array<ContextualizedTheme>) => {
  return array.sort((a, b) => {
    return a.themeContextName === 'page' ? -1 : b.themeContextName === 'page' ? 1 : 0;
  });
};

const getResolvedPageThemeData = async (
  site: CmsSite|null,
  themeCandidates: Array<ThemeCandidate> = [],
): Promise<ResolvedPageThemes> => {
  const themesToApply: Array<ContextualizedTheme> = [];

  for (const themeCandidate of themeCandidates) {
    if (themeCandidate.themeContextName === 'masthead') {
      if (typeof themeCandidate.hasMastheadContent === 'undefined') {
        throw new Error('The "hasMastheadContent" property is required for masthead themeCandidates.');
      } else if (!themeCandidate.hasMastheadContent) {
        // If no masthead content is present, we don't want to output masthead theme styles.
        continue;
      }
    }
    if (themeCandidate?.themes?.length) {
      for (const theme of themeCandidate.themes) {
        const contextAlreadyThemed = findThemeByContextName(themesToApply, themeCandidate.themeContextName);
        const isExtraContextWithoutSelection = theme.slug === noThemeSlug && themeCandidate.themeContextName !== 'page';

        if (isExtraContextWithoutSelection || (isValidTheme(theme) && !contextAlreadyThemed)) {
          themesToApply.push({
            themeContextName: themeCandidate.themeContextName,
            theme,
            sourceDescription: themeCandidate.sourceDescription,
          });
        }
      }
    }
  }

  if (!findThemeByContextName(themesToApply, 'page')) {
    const siteGlobalTheme = await getSiteTheme(site);
    const isValidSiteTheme = siteGlobalTheme === null || isValidTheme(siteGlobalTheme);

    if (siteGlobalTheme && isValidSiteTheme) {
      themesToApply.unshift({
        themeContextName: 'page',
        theme: siteGlobalTheme,
        sourceDescription: `Default theme in the CMS for site: ${site}`,
        canStationOverride: true,
      });
    } else {
      if (!isValidSiteTheme) {
        logger.error(`Invalid site global theme returned for site ${site}:`, JSON.stringify(siteGlobalTheme, null, 2));
      }

      // Use the hardcoded default theme if nothing else was found
      themesToApply.unshift( defaultThemeWithContext );
    }
  }

  return addPageThemeCss(
    'page',
    themeCandidates,
    moveBodyToBeginning( themesToApply ),
  );
};

const getHardcodedDefaultTheme = (themeStyleType: ThemeStyleType) => {
  return addPageThemeCss(
    themeStyleType,
    [ defaultThemeWithContext ],
    [ defaultThemeWithContext ],
  );
};

const setStationThemeOverride = (origThemes: ResolvedPageThemes, stationTheme: PbsKidsTheme): ResolvedPageThemes => {
  if (!stationTheme || origThemes.themes[0].canStationOverride !== true) {
    return origThemes;
  }

  const themesToApply: ContextualizedTheme = {
    themeContextName: 'page',
    theme: stationTheme,
    sourceDescription: 'Station Theme',
  };

  return addPageThemeCss(
    'page',
    [ themesToApply, ...origThemes.themeCandidates ],
    [ themesToApply ],
  );
};

export type {
  ContextualizedTheme,
  ResolvedPageThemes,
  ThemeStyleType,
};

export {
  cleanupThemeValues,
  deepClone,
  defaultThemeWithContext,
  findThemeByContextName,
  getCssVariableDeclarationsFromThemeContext,
  getHardcodedDefaultTheme,
  getImageFieldsFromCmsTheme,
  getResolvedPageThemeData,
  getThemeFromAncestors,
  isColorField,
  isCssFieldName,
  isImageField,
  isThemeMetaDataField,
  moveBodyToBeginning,
  noThemeSlug,
  setStationThemeOverride,
  singleTransparentPixel,
  sortThemeKeysInContext,
  sortThemeKeysToMatchDefault,
  themesToCss,
  transformThemeValues,
};
