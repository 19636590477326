import { ReactNode, useState, createContext, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';

import { defaultPerformanceCheckState } from '@/components/private/PerformanceCheck';
import { getQueryParam } from '@/utils/router';

import {
  isValidLevel,
  PerformanceCheckResultValue,
  PerformanceCheckTestFinalResults,
} from '@/managers/PerformanceCheck/types';

type GlobalStateContextType = {
  locale: string,
  performanceCheckState: PerformanceCheckTestFinalResults,
  performanceLevel: typeof defaultPerformanceCheckState.level,
  setLocale: (_locale: string) => void,
  setPerformanceCheckState: (_performanceCheckState: PerformanceCheckTestFinalResults) => void,
  showDevUI: boolean,
  setShowDevUI: (_showDevUI: boolean) => void,
  showThemeDevUI: boolean,
  setShowThemeDevUI: (value: boolean) => void,
};

const GlobalStateContext = createContext<GlobalStateContextType>({} as GlobalStateContextType);

export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const [ locale, setLocale ] = useState(router.locale as string);
  const [ performanceCheckState, setPerformanceCheckStateInternal ] = useState(defaultPerformanceCheckState);
  const [ performanceLevel, setPerformanceLevel ] = useState(PerformanceCheckResultValue.LEVEL_ONE);
  const [ showDevUI, setShowDevTabs ] = useState(false);
  const [ showThemeDevUI, _setShowThemeDevUI ] = useState(
    (typeof localStorage !== 'undefined' && localStorage.getItem('showThemeDevUI') === 'true') ||
    getQueryParam(router, 'showThemeDevUI') === 'true',
  );

  const setShowDevUI = useCallback((showDevUI: boolean) => {
    localStorage.setItem('showDevUI', showDevUI.toString());
    setShowDevTabs(showDevUI);
  }, [ setShowDevTabs ]);

  const setShowThemeDevUI = useCallback((value: boolean) => {
    localStorage.setItem('showThemeDevUI', value.toString());
    _setShowThemeDevUI(value);
  }, [ _setShowThemeDevUI ]);

  const setPerformanceCheckState = (state: PerformanceCheckTestFinalResults) => {
    setPerformanceCheckStateInternal(state);

    // Setting the level separately, because React won't do rerenders of pages
    // using the performance level value if it is buried in an object.
    if (isValidLevel(state.level)) {
      setPerformanceLevel(state.level);
    } else {
      setPerformanceLevel(PerformanceCheckResultValue.LEVEL_ONE);
    }
  };

  useEffect(() => {
    const qsValue = getQueryParam(router, 'showDevUI');
    const lsValue = localStorage.getItem('showDevUI');

    if (qsValue !== '') {
      setShowDevTabs(qsValue === 'true');
    } else if (typeof lsValue === 'string' && lsValue !== 'null') {
      setShowDevTabs(lsValue === 'true');
    } else {
      setShowDevTabs(
        process.env.NODE_ENV === 'development',
      );
    }
  }, [
    router,
    setShowDevTabs,
  ]);

  return <>
    <GlobalStateContext.Provider value={{
      locale,
      performanceCheckState,
      performanceLevel,
      setLocale,
      setPerformanceCheckState,
      setShowDevUI,
      setShowThemeDevUI,
      showDevUI,
      showThemeDevUI,
    }}>
      {children}
    </GlobalStateContext.Provider>
  </>;
};

export default GlobalStateContext;
